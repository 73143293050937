import i18n from "..";
import * as Validate from 'vee-validate'

export function initLocale() {
  let currentLang = navigator.language;

  if (currentLang.includes('ru')) currentLang = 'ru';
  else currentLang = 'en';

  if (localStorage.getItem('current_locale')) {
    let localeName = localStorage.getItem('current_locale');
    i18n.locale = localeName;
    import(`@/localize/${localeName}/validation`).then(locale => {
      Validate.localize(localeName, locale);
    });
  } else {
    i18n.locale = currentLang;
    import(`@/localize/${currentLang}/validation`).then(locale => {
      Validate.localize(currentLang, locale);
    });
  }
}

export function setLocale(locale) {
  localStorage.setItem('current_locale', locale);
  i18n.locale = locale;

  import(`@/localize/${locale}/validation`).then(l => {
    Validate.localize(locale, l);
  });
}

export function getTimeZone() {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}