


   export default [
    {
        name: "admin-statistics-list",
        path: "/admin/statistics",
        props: true,
        meta: {
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Statistics")
    },
]