

export default [
    { 
        name: "admin-system-settings",
        path: "settings",
        component: () => import("@/views/Administrator/Settings/Main"),
        meta: {
            pageTitle: "Основные настройки",
            group: "administrator"
        },
    },
    { 
        name: "admin-group-perms",
        path: "settings/groups-permissions",
        component: () => import("@/views/Administrator/Settings/GroupsPerms"),
        meta: {
            pageTitle: "Права групп пользователей",
            group: "administrator"
        },
    }
]