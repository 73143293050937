

    import $request from "@/modules/request/"

    export default {
            
        items: [],
        top_items: [],
        carousel_items: [],

        personal_tape: {
            items: [],
            last_page: 0,
            total_count: 0,
            current_page: 0,
            has_more_pages: false,
        },
        item : {},
        last_page: 0,
        total_count: 0,
        current_page: 0,
        has_more_pages: false,

        city: {
            id: -1,
            name: 'Вся РФ'
        },

        categories: [],
        companies: [],

        personal: {
            categories: [],
            to_income: 'none'
        },
        
        list ( forceUpdate = false, filter = {}, cb = null ) {
            
            if( this.items.length > 0 && forceUpdate === false) return;
            $request.get("offers.list", filter).then( rsp => {
                this.items = rsp.items;
                this.last_page = rsp.last_page;
                this.total_count = rsp.total_count;
                this.current_page = rsp.current_page;
                this.has_more_pages = rsp.has_more_pages;

                this.categories = rsp.categories;
                this.companies = rsp.companies;

                if (rsp.top_items.length > 0) this.top_items = rsp.top_items;
                if (rsp.carousel_items.length > 0) this.carousel_items = rsp.carousel_items;

                if (cb) cb();
            });
        },
        load ( filter = {}, cb = null ) {
            $request.get("offers.list", filter).then( rsp => {
                this.has_more_pages = rsp.has_more_pages;
                this.current_page = rsp.current_page;
                this.items.push( ...rsp.items );

                this.categories = rsp.categories;
                this.companies = rsp.companies;

                if (cb) cb();
            });
        },
        get (id, cb = null ) {
            this.item = {};
            $request.get("offers.get", {id: id}).then( rsp => {
                this.item = rsp.item;

                if (cb) cb(this.item);
            }).catch(error => {
                console.log('Нет такого оффера, уводить на 404 страницу');
            });
        },
        getCategories () {
            $request.get("categories.list").then( rsp => {
                this.categories = rsp.items;
            });
        },
        getCompanies () {
            $request.get("companies.list").then( rsp => {
                this.companies = rsp.items;
            });
        },
        getPersonalTapeSettings (cb = null) {
            $request.get("offers.getPersonalTapeSettings").then( rsp => {
                rsp.categories.map(function(cat){
                    if (rsp.personal.categories.find(item => item == cat.id)) return cat.personal = true;
                    return cat.personal = false;
                })
                this.personal.categories = rsp.categories;
                this.personal.to_income = rsp.personal.to_income;

                if (cb) cb(rsp);
            });
        },
        updatePersonalTape (categories, to_income, action = 'add', cb = null) {

            $request.post("offers.updatePersonalTape", {categories: categories, to_income: to_income}).then( rsp => {
                if (action == 'remove') {
                    rsp.categories.map(function(cat){
                        if (rsp.personal.categories.find(item => item == cat.id)) return cat.personal = true;
                        return cat.personal = false;
                    })
                    this.personal.categories = rsp.categories; 
                    this.personal.to_income = rsp.personal.to_income;

                    if (rsp.personal.length == 0) {
                        this.personal_tape = {
                            items: [],
                            last_page: 0,
                            total_count: 0,
                            current_page: 0,
                            has_more_pages: false,
                        };
                    }
                }

                if (cb) cb(rsp);
            });
        },
        getPersonalTape (filter = {}, cb = null) {
            $request.get("offers.getPersonalTape", filter).then( rsp => {
                if (rsp.current_page == 1) {
                    this.personal_tape.items = rsp.items;
                } else {
                    this.personal_tape.items.push( ...rsp.items );
                }

                this.personal_tape.last_page = rsp.last_page;
                this.personal_tape.total_count = rsp.total_count;
                this.personal_tape.current_page = rsp.current_page;
                this.personal_tape.has_more_pages = rsp.has_more_pages;

                if (cb) cb();
            });
        },
        install(Vue) {
            Vue.prototype.$offers = this;            
        } 
    }