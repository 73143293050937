<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
        <transition
                name="fade-bottom"
                mode="out-in"
            >
            <component :is="layout">
                  <router-view />
            </component>
        </transition>


        <!-- <div v-if="isCanShowPreloader" id="app-preloader" class="preloader d-flex">
            <div class="m-auto d-flex">
                <div class="bounceball"></div>
            </div>
        </div> -->

       <feed-back-modal />

  </div>
</template>

<script>

import $request from "@/modules/request/"
import User from "@/modules/user/"
import Offers from "@/modules/offers/";

import { initLocale } from "@/libs/i18n/modules/init-locale"
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const TWEEN = require('@tweenjs/tween.js')


const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutSite = () => import('@/layouts/site/LayoutSite.vue')
const LayoutCustom = () => import('@/layouts/custom/LayoutCustom.vue')
// Demo
// const LayoutComingSoon = () => import('@/layouts/previews/LayoutComingSoon.vue')

const FeedBackModal = () => import("@/components/FeedBackModal.vue");

export default {

  data() {
      return {
          isCanShowPreloader: false,
          // utm_tags: new URLSearchParams(window.location.search).get("utm_source")
          url_params: new URLSearchParams(window.location.search)
      }
  },

  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutSite,
    LayoutCustom,

    // Demo
    // LayoutComingSoon,

    // Modals
    FeedBackModal,


  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {

    layout() {
        const { skin } = useAppConfig();

        if (this.$route.meta.layout === 'coming-soon') {
          if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
        }
        else if( User.isAuth() ) {
          // If skin is dark when initialized => Add class to body
          if (skin.value === 'dark') document.body.classList.add('dark-layout')
        }

        if (this.$route.meta.layout === 'coming-soon') return 'layout-coming-soon'
        if (this.$route.meta.layout === 'full') return 'layout-full'
        if (this.$route.meta.layout === 'landing') return 'layout-landing'
        if (this.$route.meta.layout === 'site' ) return 'layout-site';
        if (this.$route.meta.layout === 'custom' ) return 'layout-custom';

        return `layout-${this.contentLayoutType}`
    },

    contentLayoutType() {
      return $themeConfig.layout.type;
    },

  },

  mounted() {

        var permissionErrorFired = false;

        $request.permissionError = () => {

            if( permissionErrorFired ) return;
            permissionErrorFired = true;

            localStorage.removeItem('current:user');
            localStorage.removeItem('access_token');

            this.$router.replace({ name: "auth-login" });

            setTimeout(() => {
                permissionErrorFired = false;
            }, 2000 );

        }

        function animate(time) {
            requestAnimationFrame(animate)
            TWEEN.update(time)
        }

        requestAnimationFrame(animate)

        setTimeout(() => {

            let preloader = document.getElementById('app-preloader');

            let initCoordsState = {
                y: 0,
            }

            let initOpacityState = {
                opacity: 1
            }

            if( preloader ) {
                new TWEEN.Tween( initCoordsState )
                    .to({
                        y: window.screen.height * (-1)
                    }, 355 )
                    .onUpdate(obj => {
                        preloader.style.setProperty('top', `${obj.y}px`);
                    })
                    .start();

                new TWEEN.Tween( initOpacityState )
                .to({
                    opacity: 0
                }, 355 )
                .onUpdate(obj => {
                    preloader.style.setProperty('opacity', `${obj.opacity}`);
                })
                .onComplete(() => {
                    this.isCanShowPreloader = false;
                })
                .start();
            } else {
                this.isCanShowPreloader = false;
            }

       }, 3500 );

        if (this.url_params.get('confirm') && this.url_params.get('confirm') == 'email') {
          localStorage.setItem("confirmed:email", true);
        }

  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')


    function decimalAdjust(type, value, exp) {
        // If the exp is undefined or zero...
        if (typeof exp === 'undefined' || +exp === 0) {
          return Math[type](value);
        }
        value = +value;
        exp = +exp;
        // If the value is not a number or the exp is not an integer...
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
          return NaN;
        }
        // If the value is negative...
        if (value < 0) {
          return -decimalAdjust(type, -value, exp);
        }
        // Shift
        value = value.toString().split('e');
        value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
      }

    // Decimal round
    if (!Math.round10) {
      Math.round10 = function(value, exp) {
        return decimalAdjust('round', value, exp);
      };
    }
    // Decimal floor
    if (!Math.floor10) {
      Math.floor10 = function(value, exp) {
        return decimalAdjust('floor', value, exp);
      };
    }
    // Decimal ceil
    if (!Math.ceil10) {
      Math.ceil10 = function(value, exp) {
        return decimalAdjust('ceil', value, exp);
      };
    }

    Number.prototype.formatMoney = function(places, symbol, thousand, decimal) {
      var number = this;

      if (places == 'auto') {
        places = (number.toString().includes('.')) ? (number.toString().split('.').pop().length) : (0);
      } else {
        places = !isNaN(places = Math.abs(places)) ? places : 2;
      }
      symbol = symbol !== undefined ? symbol : "$";
      thousand = thousand || " ";
      decimal = decimal || ",";

      var  negative = number < 0 ? "-" : "",
          i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
          j = (j = i.length) > 3 ? j % 3 : 0;
      return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
    };

    Number.prototype.decimalBtcMoney = function() {
            var number = this

            return Math.ceil10(number, -8);
    };

    Number.prototype.toShort = function() {
        var value = this;
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "k", "m", "b","t"];
            var suffixNum = Math.floor( (""+value).length/3 );
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }

    // set lang
    initLocale();

    // Utils.historyData.get();
    // Cache.get();
    // News.get();

    if( User.isAuth() ) {
        User.get();

//        store.commit( 'appConfig/UPDATE_NAV_MENU_HIDDEN', false )
    } else {
//        store.commit( 'appConfig/UPDATE_NAV_MENU_HIDDEN', true )
    }


  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // if( User.isAuth() ) {
    //   // If skin is dark when initialized => Add class to body
    //   if (skin.value === 'dark') document.body.classList.add('dark-layout')
    // }

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>


<style>
    .card {
        border-radius: 32px !important;
        box-shadow: 0px 10px 14px rgba(99, 109, 137, 0.01) !important;
    }
</style>
