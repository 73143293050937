export default {
    code: "ru",
    messages: {
        "alpha": "Поле может содержать только буквы",
        "alpha_dash": "Поле может содержать только буквы, цифры и дефис",
        "alpha_num": "Поле может содержать только буквы и цифры",
        "alpha_spaces": "Поле может содержать только буквы и пробелы",
        "between": "Поле должно быть между {min} и {max}",
        "confirmed": "Пароли не совпадают",
        "digits": "Поле должно быть числовым и длиной {length}",
        "dimensions": "Поле должно быть {width} пикселей на {height} пикселей",
        "email": "Поле должно быть действительным электронным адресом",
        "excluded": "Поле должно быть допустимым значением",
        "ext": "Поле должно быть действительным файлом. ({args})",
        "image": "Поле должно быть изображением",
        "oneOf": "Поле должно быть допустимым значением",
        "integer": "Поле должно быть целым числом",
        "length": "Длина поля должна быть {length}",
        "max": "Поле не может быть более {length} символов",
        "max_value": "Поле должно быть {max} или менее",
        "mimes": "Поле должно иметь допустимый тип файла. ({args})",
        "min": "Поле  должно быть не менее {length} символов",
        "min_value": "Поле должно быть {min} или больше",
        "numeric": "Поле должно быть числом",
        "regex": "Поле имеет ошибочный формат",
        "required": "Поле обязательно для заполнения",
        "required_if": "Поле обязательно для заполнения",
        "size": "Поле должно быть меньше, чем {size}KB",
        "double": "Поле должно быть допустимым десятичным числом",
        "password": "Ваш пароль должен содержать хотя бы один верхний регистр, один нижний регистр, один специальный символ и одну цифру."
    }  
}
