export default [
    { 
        path: "/dashboard/offers/premium",
        name: "user-offers-premium",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/special",
        name: "user-offers-special",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/personal",
        name: "user-offers-personal",
        component: () => import("@/views/User/offers/personal"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/account-opening",
        name: "user-offers-account-opening",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/debit-card",
        name: "user-offers-debit-card",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/credit",
        name: "user-offers-credit",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/express-credit",
        name: "user-offers-express-credit",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/car-credit",
        name: "user-offers-car-credit",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/investments",
        name: "user-offers-investments",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/tourism",
        name: "user-offers-tourism",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/vpn",
        name: "user-offers-vpn",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/betting",
        name: "user-offers-betting",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/osago",
        name: "user-offers-osago",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/kasko",
        name: "user-offers-kasko",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
    { 
        path: "/dashboard/offers/hr",
        name: "user-offers-hr",
        component: () => import("@/views/User/offers/catalog"),
        meta: {
            pageTitle: "",
            group: "user",
        },
    },
]