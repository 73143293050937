export default [
    {
        path: "/coming-soon",
        name: "home-page",
        visibility: "public",
        component: () => import("@/views/Main/demo/views/comingSoon"),
        meta: {
            redirectIfLoggedIn: true,
            layout: 'coming-soon',
            title: 'Coming Soon | 2 Work',
            md: true,
            group: "user"
        },
    }
]