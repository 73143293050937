


export default [
        
    { 
        path: "/dashboard/account/info",
        name: "user-account-info",
        component: () => import("@/views/User/account/account-info.vue"),
        meta: {
            pageTitle: "Личная информация",
            group: "user"
        }
    },
    { 
        path: "/dashboard/account/password",
        name: "user-account-password",
        component: () => import("@/views/User/account/account-password.vue"),
        meta: {
            pageTitle: "Смена пароля",
            group: "user"
        }
    },
    { 
        path: "/dashboard/account/loyalty",
        name: "user-account-loyalty",
        component: () => import("@/views/User/account/account-loyalty.vue"),
        meta: {
            pageTitle: "Программа лояльности",
            group: "user"
        }
    },
    { 
        path: "/dashboard/account/subscriptions",
        name: "user-account-subscriptions",
        component: () => import("@/views/User/account/account-subscriptions.vue"),
        meta: {
            pageTitle: "Подписки",
            group: "user"
        }
    }
        
]