

import dashboard from "./dashboard"
import settings from "./settings/user"
import catalog from "./catalog"

export default [
    { 
        path: "/dashboard",
        component: () => import("@/views/User/dashboard/dashboard"),
        meta: {
            pageTitle: "Сводка данных",
            group: "user"
        },
        children: dashboard
    },
    { 
        path: "/dashboard/offer/id:item",
        name: "user-offer-item",
        component: () => import("@/views/User/offers/item/"),
        meta: {
            pageTitle: "Предложение",
            group: "user"
        },
    },
    { 
        path: "/dashboard/favourites",
        name: "user-favourites",
        component: () => import("@/views/User/favourites"),
        meta: {
            pageTitle: "Избранное",
            group: "user"
        },
    },
    { 
        path: "/dashboard/statistics",
        name: "user-statistics",
        component: () => import("@/views/User/statistics"),
        meta: {
            pageTitle: "Статистика",
            group: "user",
            verticalNavbar: 'hidden'
        },
    },
    { 
        path: "/dashboard/finance",
        name: "user-finance",
        component: () => import("@/views/User/finance"),
        meta: {
            pageTitle: "Финансы",
            group: "user",
            verticalNavbar: 'hidden'
        },
    },
    { 
        path: "/dashboard/orders",
        name: "user-orders",
        component: () => import("@/views/User/orders"),
        meta: {
            pageTitle: "Заявки",
            group: "user",
            verticalNavbar: 'hidden'
        },
    },
    { 
        path: "/dashboard/team",
        name: "user-team",
        component: () => import("@/views/User/team"),
        meta: {
            pageTitle: "Команда",
            group: "user",
            verticalNavbar: 'hidden'
        },
    },
    {
        path: "/documents",
        name: "user-documents",
        component: () => import("@/views/User/documents/documents"),
        meta: {
            visibility: "public",
            pageTitle: "Документы",
            group: "user",
            verticalNavbar: 'hidden'
        }
    },
    {
        path: "/documents/:type",
        name: "user-documents-noauth",
        component: () => import("@/views/User/documents/documents"),
        meta: {
            visibility: "public",
            pageTitle: "Документы",
            group: "user",
            verticalNavbar: 'hidden'
        }
    },

    ...settings,
    ...catalog
  
]