


   export default [
    {
        name: "admin-offers-list",
        path: "/admin/offers",
        props: true,
        meta: {
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Offers/list")
    },
    {
        path: "/admin/offers/add",
        name: "admin-offers-add",
        props: true,
        meta: {
            pageTitle: "Создание предложения",
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Offers/add")
    },
    {
        path: "/admin/offers/update",
        name: "admin-offers-update",
        props: true,
        meta: {
            pageTitle: "Редактирование предложения",
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Offers/edit")
    },
    {
        path: "categories",
        name: "admin-offers-categories",
        component: () => import("@/views/Administrator/Offers/categories"),
        props: true,
        meta: {
            pageTitle: "Категории",
            group: "administrator"
        },
    },
    {
        path: "companies",
        name: "admin-offers-companies",
        component: () => import("@/views/Administrator/Offers/companies"),
        props: true,
        meta: {
            pageTitle: "Компании",
            group: "administrator"
        },
    },
]