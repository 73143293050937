

    export default [
        {
            path: "/",
            component: () => import("@/views/Viewer"),
            meta: {
                redirectIfLoggedIn: true,
                meta: {
                    verticalNavbar: 'hidden'
                }
            },
            children: [
                {
                    path: "",
                    name: "welcome-page",
                    component: () => import("@/views/Main/site/v1/"),
                    meta: {
                        redirectIfLoggedIn: true,
                        layout: 'site',
                        verticalNavbar: 'hidden'
                    },
                },
              
            ]
        },
    ]
