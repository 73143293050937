


    export default [
        {
            path: "",
            name: "user-dashboard",
            component: () => import("@/views/User/dashboard/views/main"),
            meta: {
                group: "user",
                visibility: "auth",
            }
        },
        
    ]