


   export default [
    {
        name: "admin-applications-opening-offers-list",
        path: "/admin/applications-opening",
        props: true,
        meta: {
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Offers/applicationsOpening/list")
    },
   
]