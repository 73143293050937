
import users from "./users/admin";
import offers from "./offers/admin";
import applicationsOpening from "./offers/applicationsOpening";
import settings from "./settings/admin";
import finance from "./finance/admin";
import statistics from "./statistics/admin";
import orders from "./orders/admin";
import errors from "./errors/admin";

var routes = [
    { 
        path: "/admin",
        name: "admin",
        component: () => import("@/views/Administrator/"),
        meta: {
            pageTitle: "Панель администратора 2work",
            group: "administrator"
        },
        children: [
            { 
                path: "",
                name: "admin-dashboard",
                component: () => import("@/views/Administrator/Users/list"),
                meta: {
                    pageTitle: "Панель администратора 2work",
                    group: "administrator"
                },
            },
            
            ...settings,
            ...users,
            ...offers,
            ...applicationsOpening,
            ...finance,
            ...orders,
            ...statistics,
            ...errors
        ]
    },
    
];

export default routes;