


   export default [
    {
        name: "admin-payouts-list",
        path: "/admin/payouts",
        props: true,
        meta: {
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Finance/Payouts/list")
    },
]