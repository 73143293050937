export default {
    state: {
        earnOption: 0 //2 секция на главной
    },
    actions: {
        setEarnOption(ctx, option) {
            ctx.commit("updateEarnOption", option)
        }
    },
    mutations: {
        updateEarnOption(state, payload) {
            state.earnOption = payload
        }
    },
    getters: {
        getEarnOption(state) {
            return state.earnOption
        }
    },
}
